import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/core";
import { ThemeProvider } from "emotion-theming";
import styles from "../constants/theme";

import SEO from "../components/seo";
import Layout from "../components/layout";
import Text from "../components/Text";
import Hero from "../components/Hero";
import MainContentWrap from "../components/MainContentWrap";
import ContentSection from "../components/ContentSection";
import CustomContainer from "../components/CustomContainer";
import StyledLink from "../components/StyledLink";
import GatsbyImage from "gatsby-image";

export const pageQuery = graphql`
  query($id: String!) {
    pdPage: wordpressPage(id: { eq: $id }) {
      title
      acf {
        heading
        person_name
        person_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 150, toFormat: JPG, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        person_bio
        link_button_page {
          __typename
          ... on WordPressAcf_youtube {
            text
            url
          }
        }
        link_button_2_page {
          __typename
          ... on WordPressAcf_pdf_file {
            text
            file {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;

export default function PD2017({ data }) {
  const { acf } = data.pdPage;
  return (
    <Layout>
      <SEO title={data.pdPage.title} keywords={[""]} />
      <ThemeProvider theme={styles.palette.blue}>
        <Hero>
          <div className="container text-center">
            <Text h1>{data.pdPage.title}</Text>
          </div>
        </Hero>
      </ThemeProvider>
      <MainContentWrap>
        <CustomContainer>
          <ContentSection>
            <div
              dangerouslySetInnerHTML={{ __html: acf.heading }}
              css={css`
                margin-bottom: ${styles.margin.mid};
              `}
            />
            {acf.link_button_page && (
              <div
                css={css`
                  margin-bottom: ${styles.margin.mid};
                `}
              >
                {acf.link_button_page.map((link, i) => (
                  <StyledLink block to={link.url} key={`pd_button_link_${i}`}>
                    {link.text}
                  </StyledLink>
                ))}
              </div>
            )}

            <div
              className="row"
              css={css`
                margin-bottom: ${styles.margin.mid};
              `}
            >
              <div className="col-md-3 text-center">
                <GatsbyImage fluid={acf.person_image.localFile.childImageSharp.fluid} />
                <Text>{acf.person_name}</Text>
              </div>
              <div className="col">{acf.person_bio}</div>
            </div>
            {acf.link_button_2_page &&
              acf.link_button_2_page.map((link, i) => (
                <StyledLink block to={link.file.localFile.publicURL} key={`pd_button_2_link_${i}`}>
                  {link.text}
                </StyledLink>
              ))}
          </ContentSection>
        </CustomContainer>
      </MainContentWrap>
    </Layout>
  );
}
